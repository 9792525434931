.request_next_btn_slide {
  color: #fff;
  background: #000136;
  border-radius: 0px;
  padding: 15px 30px;
  margin: 20px 0 20px 0;
  display: inline-block;
  font-family: 'aeonik-pro-trial', sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
  border-right: 15px solid #7700ec;
  box-shadow: inset 0 0 0 0 #7700ec;
  cursor: pointer;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.request_nexr_slide_left:hover {
  padding-right: 10px;
  box-shadow: inset -135px 0 0 0 #7700ec;
}
