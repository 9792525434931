.landing_page_container .register_container {
  width: 100%;
  padding: 25px 100px 20px 100px;
  margin-top: 120px;
  margin-bottom: 60px;
}
.landing_page_container .register_container .register_contant {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.landing_page_container
  .register_container
  .register_contant
  .register_contant_left,
.landing_page_container
  .register_container
  .register_contant
  .register_contant_right {
  width: 50%;
}
.landing_page_container
  .register_container
  .register_contant
  .register_contant_left
  .register_contant_heading {
  font-size: 70px;
  color: #7700ec;
  margin-bottom: 25px;
  font-family: 'aeonik-pro-trial', sans-serif;
}
.landing_page_container
  .register_container
  .register_contant
  .register_contant_left
  .register_contant_para {
  font-size: 28px;
  color: #060621;
  font-family: 'aeonik-pro-trial', sans-serif;
  font-weight: 600;
  line-height: 31px;
}
.landing_page_container
  .register_container
  .register_contant
  .register_contant_right
  .image_container_style {
  width: 100%;
  text-align: center;
}
.landing_page_container
  .register_container
  .register_contant
  .register_contant_right
  .image_container_style
  img {
  width: 100%;
}

.landing_page_container .login_container {
  width: 100%;
  padding: 60px 100px;
  background: #f5f5f7;
  text-align: center;
}
.landing_page_container .login_container .login_heading {
  margin-bottom: 35px;
  font-size: 40px;
  font-family: 'aeonik-pro-trial', sans-serif;
  font-weight: 800;
}
.landing_page_container .login_container .input_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
}
.landing_page_container .login_container .input_container_details {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  margin-bottom: 30px;
}
.landing_page_container .login_container .input_container .inputfield {
  width: 45%;
  height: 40px;
}
.landing_page_container .login_container .input_container_otp {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 40px;
}
.landing_page_container .login_container .input_container_otp .inputfield {
  width: 45%;
  height: 40px;
}
.landing_page_container .login_container .input_container_otp .otp_para {
  width: 45%;
  text-align: center;
  margin-top: 8px;
  font-size: 15px;
  font-family: 'aeonik-pro-trial', sans-serif;
  font-weight: 300;
}
.landing_page_container .login_container .did_not_recieved_otp {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.landing_page_container
  .login_container
  .did_not_recieved_otp
  .did_not_recieved_otp_text {
  font-size: 15px;
  font-family: 'aeonik-pro-trial', sans-serif;
  font-weight: 300;
  color: #000136;
  margin-right: 15px;
}
.landing_page_container .login_container .did_not_recieved_otp .re_send_otp {
  border: none;
  color: #7700ec;
  text-decoration: underline;
  cursor: pointer;
}
.landing_page_container .login_container .request_again_otp_para {
  font-size: 15px;
  font-family: 'aeonik-pro-trial', sans-serif;
  font-weight: 300;
  color: #000136;
  margin-top: 10px;
  margin-bottom: 80px;
}
.landing_page_container .login_container .request_again_otp_para span {
  color: #7700ec;
}

.landing_page_container .login_container .input_container_Pnumber {
  width: 100%;
}
.landing_page_container
  .login_container
  .input_container_Pnumber
  .phone_number {
  width: 45%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 40px;
}
.landing_page_container
  .login_container
  .input_container_Pnumber
  .phone_number
  .phone_code {
  width: 100pxpx;
  height: 40px;
  border: none;
  outline: none;
}
.landing_page_container
  .login_container
  .input_container_Pnumber
  .phone_number
  .inputfield {
  width: 100%;
  height: 40px;
}
.landing_page_container
  .login_container
  .input_container_Pnumber
  .login_details_para {
  width: 100%;
  margin-top: 10px;
  text-align: start;
  padding: 0 40px;
  font-size: 12px;
  line-height: 19px;
  font-family: 'aeonik-pro-trial', sans-serif;
  font-weight: 300;
  color: #000136;
}
.landing_page_container .login_container .save_btn {
  width: 100%;
  margin-bottom: 60px;
}

.landing_page_container .login_container .register_video {
  width: 500px;
  /* height: 500px; */
}

@media only screen and (max-width: 1203px) {
  .landing_page_container
    .register_container
    .register_contant
    .register_contant_left,
  .landing_page_container
    .register_container
    .register_contant
    .register_contant_right {
    width: 48%;
  }
}
@media only screen and (max-width: 1166px) {
  .landing_page_container .register_container {
    width: 100%;
    padding: 25px 100px 20px 100px;
    margin-top: 100px;
    margin-bottom: 60px;
  }
  .landing_page_container
    .register_container
    .register_contant
    .register_contant_left
    .register_contant_heading {
    font-size: 50px;
    color: #7700ec;
    margin-bottom: 25px;
    font-family: 'aeonik-pro-trial', sans-serif;
  }
  .landing_page_container
    .register_container
    .register_contant
    .register_contant_left,
  .landing_page_container
    .register_container
    .register_contant
    .register_contant_right {
    width: 50%;
  }
}
@media only screen and (max-width: 768px) {
  .landing_page_container .register_container {
    width: 100%;
    padding: 50px 100px 20px 100px;
    margin-top: 100px;
    /* margin-bottom: 60px; */
  }
  .landing_page_container .register_container .register_contant {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .landing_page_container
    .register_container
    .register_contant
    .register_contant_left
    .register_contant_heading {
    font-size: 60px;
    color: #7700ec;
    margin-bottom: 25px;
    font-family: 'aeonik-pro-trial', sans-serif;
  }
  .landing_page_container
    .register_container
    .register_contant
    .register_contant_left,
  .landing_page_container
    .register_container
    .register_contant
    .register_contant_right {
    width: 100%;
    text-align: center;
  }
  .landing_page_container
    .register_container
    .register_contant
    .register_contant_left {
    margin-bottom: 20px;
  }
  .landing_page_container .login_container {
    width: 100%;
    padding: 60px 50px;
    background: #f5f5f7;
    text-align: center;
  }
  .landing_page_container .login_container .input_container_otp .otp_para {
    font-size: 12px;
  }
  .landing_page_container .login_container .register_video {
    width: 350px;
  }
}
@media only screen and (max-width: 702px) {
  .landing_page_container .register_container {
    padding: 25px 70px 20px 70px;
  }
  .landing_page_container .login_container {
    padding: 60px 20px;
  }
}
@media only screen and (max-width: 602px) {
  .landing_page_container .register_container {
    padding: 60px 40px 20px 40px;
  }
}
@media only screen and (max-width: 660px) {
  .landing_page_container
    .login_container
    .input_container_Pnumber
    .phone_number
    .phone_code {
    width: 100px;
  }
}
@media only screen and (max-width: 628px) {
  .landing_page_container
    .login_container
    .input_container_Pnumber
    .phone_number
    .phone_code {
    width: 100px;
  }
}
@media only screen and (max-width: 610px) {
  .landing_page_container
    .login_container
    .input_container_Pnumber
    .phone_number
    .phone_code {
    width: 100px;
  }
}
@media only screen and (max-width: 582px) {
  .landing_page_container
    .login_container
    .input_container_Pnumber
    .phone_number
    .phone_code {
    width: 100px;
  }
}
@media only screen and (max-width: 534px) {
  .landing_page_container .login_container .input_container {
    padding: 0 20px;
  }
  .landing_page_container .login_container .input_container .inputfield {
    width: 48%;
  }
  .landing_page_container .login_container .input_container_otp {
    padding: 0 20px;
  }
  .landing_page_container .login_container .input_container_otp .inputfield {
    width: 48%;
    height: 40px;
  }
  .landing_page_container .login_container .input_container_otp .otp_para {
    width: 48%;
  }
  .landing_page_container
    .login_container
    .input_container_Pnumber
    .phone_number {
    padding: 0 0 0 20px;
  }
  .landing_page_container
    .login_container
    .input_container_Pnumber
    .phone_number {
    width: 50%;
    padding-right: 10px;
  }
  .landing_page_container
    .login_container
    .input_container_Pnumber
    .phone_number
    .phone_code {
    width: 100px;
    margin-right: 0px;
  }
  .login_container .input_container_Pnumber .phone_number .inputfield {
    width: 100%;
    height: 40px;
  }
  .landing_page_container
    .login_container
    .input_container_Pnumber
    .login_details_para {
    padding: 0 20px;
  }
}
@media only screen and (max-width: 425px) {
  .landing_page_container .register_container {
    padding: 15px 20px 15px 20px;
  }
  .landing_page_container
    .register_container
    .register_contant
    .register_contant_left
    .register_contant_heading {
    font-size: 35px;
  }
  .landing_page_container .login_container .input_container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .landing_page_container .login_container .input_container .inputfield {
    width: 100%;
    margin-bottom: 15px;
  }
  .landing_page_container .login_container .input_container_otp {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .landing_page_container .login_container .input_container_otp .inputfield {
    width: 100%;
  }
  .landing_page_container .login_container .input_container_otp .otp_para {
    width: 100%;
    text-align: center;
    margin-top: 8px;
    font-size: 15px;
    font-family: 'aeonik-pro-trial', sans-serif;
    font-weight: 300;
  }
  .landing_page_container .login_container .register_video {
    width: 300px;
  }
  .landing_page_container
    .login_container
    .input_container_Pnumber
    .phone_number {
    width: 100%;
    padding-right: 20px;
  }
  .landing_page_container
    .login_container
    .input_container_Pnumber
    .phone_number
    .phone_code {
    width: 100px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 375px) {
  .landing_page_container .register_container {
    padding: 5px 10px 5px 10px;
  }
  .landing_page_container .login_container .register_video {
    width: 300px;
  }
  .landing_page_container
    .login_container
    .input_container_Pnumber
    .login_details_para {
    font-size: 11px;
  }
}
@media only screen and (max-width: 335px) {
  .landing_page_container .login_container .register_video {
    width: 250px;
  }
  .landing_page_container
    .login_container
    .input_container_Pnumber
    .phone_number
    .phone_code {
    width: 100px;
    margin-right: 0px;
  }
}
