.otp_btn_slide {
  color: #fff;
  background: #000136;
  border-radius: 0px;
  padding: 10px 30px;
  margin: 50px 0;
  display: inline-block;
  font-family: 'aeonik-pro-trial', sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
  border-right: 15px solid #7700ec;
  box-shadow: inset 0 0 0 0 #7700ec;
  cursor: pointer;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.slide_left:hover {
  padding-right: 10px;
  box-shadow: inset -140px 0 0 0 #7700ec;
}

@media only screen and (max-width: 375px) {
  .otp_btn_slide {
    padding: 10px 20px;
  }
}
