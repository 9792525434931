.landing_page_container .guideline_container {
  width: 100%;
  padding: 100px 100px 60px 100px;
  margin-top: 50px;
  margin-bottom: 20px;
  background-color: #f5f5f7;
  /* border-bottom: 1.5px solid #444444; */
  /* border: 1px solid red; */
}
.landing_page_container .guideline_container .guideline_contant {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.landing_page_container
  .guideline_container
  .guideline_contant
  .guideline_contant_left,
.landing_page_container
  .guideline_container
  .guideline_contant
  .guideline_contant_right {
  width: 50%;
}
/* .landing_page_container
  .guideline_container
  .guideline_contant
  .guideline_contant_right {
  position: relative;
} */
.landing_page_container
  .guideline_container
  .guideline_contant
  .guideline_contant_left
  .guideline_contant_heading {
  font-size: 80px;
  color: #7700ec;
  margin-bottom: 25px;
  font-family: 'aeonik-pro-trial', sans-serif;
}
/* .landing_page_container
  .guideline_container
  .guideline_contant
  .guideline_contant_left
  .guideline_contant_para {
  font-size: 30px;
  color: #060621;
  font-family: 'aeonik-pro-trial', sans-serif;
  font-weight: 600;
  line-height: 37px;
} */
.landing_page_container
  .guideline_container
  .guideline_contant
  .guideline_contant_right
  .image_container_style
  .gif {
  position: absolute;
  bottom: 20px;
  left: 32px;
  width: 50px;
}
.landing_page_container
  .guideline_container
  .guideline_contant
  .guideline_contant_right
  .image_container_style {
  width: 100%;
  text-align: center;
  position: relative;
}

.landing_page_container
  .guideline_container
  .guideline_contant
  .guideline_contant_right
  .image_container_style
  .slideImage {
  width: 75%;
}

.landing_page_container .guideline_details_container {
  width: 100%;
  padding: 100px 100px 40px 100px;
  background: #ffffff;
  /* border-bottom: 1.5px solid #444444; */
}
.landing_page_container .guideline_details_container .font_inc {
  white-space: pre-line;
  font-size: 17px;
  margin-bottom: 25px;
  line-height: 20px;
  font-family: 'aeonik-pro-trial', sans-serif;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.landing_page_container
  .guideline_details_container
  .guideline_details_contant {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.landing_page_container
  .guideline_details_container
  .guideline_details_contant_reverse {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin: 80px 0;
}
.landing_page_container
  .guideline_details_container
  .guideline_details_contant
  .guideline_details_contant_left,
.landing_page_container
  .guideline_details_container
  .guideline_details_contant_reverse
  .guideline_details_contant_left {
  width: 40%;
}
.landing_page_container
  .guideline_details_container
  .guideline_details_contant
  .guideline_details_contant_left
  .guideline_details_contant_heading,
.landing_page_container
  .guideline_details_container
  .guideline_details_contant_reverse
  .guideline_details_contant_left
  .guideline_details_contant_heading {
  margin-bottom: 45px;
  font-family: 'aeonik-pro-trial', sans-serif;
  font-weight: 700;
}
.landing_page_container
  .guideline_details_container
  .guideline_details_contant
  .guideline_details_contant_left
  .guideline_details_contant_para,
.landing_page_container
  .guideline_details_container
  .guideline_details_contant_reverse
  .guideline_details_contant_left
  .guideline_details_contant_para {
  white-space: pre-line;
  font-size: 14px;
  margin-bottom: 25px;
  line-height: 21px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: 'aeonik-pro-trial', sans-serif;
}
.landing_page_container
  .guideline_details_container
  .guideline_details_contant
  .guideline_details_contant_left
  .guideline_details_contant_heading,
.landing_page_container
  .guideline_details_container
  .guideline_details_contant_reverse
  .guideline_details_contant_left
  .guideline_details_contant_heading {
  margin-bottom: 45px;
}
.landing_page_container
  .guideline_details_container
  .guideline_details_contant
  .guideline_details_contant_right,
.landing_page_container
  .guideline_details_container
  .guideline_details_contant_reverse
  .guideline_details_contant_right {
  width: 48%;
}
.landing_page_container
  .guideline_details_container
  .guideline_details_contant
  .guideline_details_contant_right
  .event_contant_video,
.landing_page_container
  .guideline_details_container
  .guideline_details_contant_reverse
  .event_contant_video {
  width: 100%;
}
.landing_page_container
  .guideline_details_container
  .guideline_details_contant
  .event_contant_video
  .slideImage,
.landing_page_container
  .guideline_details_container
  .guideline_details_contant_reverse
  .event_contant_video
  .slideImage {
  width: 100%;
}
.landing_page_container
  .guideline_details_container
  .guideline_details_contant
  .event_contant_video
  .slideImage,
.landing_page_container
  .guideline_details_container
  .guideline_details_contant_reverse
  .event_contant_video
  .slideImage {
  width: 100%;
}
.landing_page_container
  .guideline_details_container
  .guideline_details_contant
  .guideline_details_contant_right
  .event_contant_videoe {
  width: 100%;
}
.landing_page_container
  .guideline_details_container
  .guideline_details_contant
  .guideline_details_contant_right
  .event_contant_videoe
  .slideImage {
  width: 100%;
}

.landing_page_container .faqs_container {
  width: 100%;
  padding: 60px 100px 60px 100px;
  margin-top: 40px;
  background-color: #f5f5f7;
}
.landing_page_container .faqs_container .faqs_contant {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.landing_page_container .faqs_container .faqs_contant .faqs_contant_left,
.landing_page_container .faqs_container .faqs_contant .faqs_contant_right {
  width: 50%;
}
.landing_page_container
  .faqs_container
  .faqs_contant
  .faqs_contant_left
  .faqs_contant_heading {
  font-size: 85px;
  color: #7700ec;
  margin-bottom: 25px;
  font-family: 'aeonik-pro-trial', sans-serif;
}
.landing_page_container
  .faqs_container
  .faqs_contant
  .faqs_contant_left
  .faqs_contant_para {
  font-size: 30px;
  color: #060621;
  font-family: 'aeonik-pro-trial', sans-serif;
  font-weight: 600;
  line-height: 37px;
}
.landing_page_container
  .faqs_container
  .faqs_contant
  .faqs_contant_right
  .image_container_style {
  width: 100%;
  text-align: center;
}
.landing_page_container
  .faqs_container
  .faqs_contant
  .faqs_contant_right
  .image_container_style
  .slideImage {
  width: 100%;
}

.landing_page_container .guideline_days_container {
  width: 100%;
  margin-top: 50px;
  padding: 0 100px 0 100px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: 'aeonik-pro-trial', sans-serif;
}
.landing_page_container .guideline_days_container .guidline_rules_left,
.landing_page_container .guideline_days_container .guidline_rules_bottom,
.landing_page_container .guideline_days_container .guidline_rules_right {
  width: 33%;
  text-align: start;
  padding: 15px 15px;
  margin-bottom: 50px;
}
.landing_page_container .guideline_days_container .guidline_rules_left,
.landing_page_container .guideline_days_container .guidline_rules_right {
  background-color: #f5f5f7;
}
.landing_page_container
  .guideline_days_container
  .guidline_rules_left
  .guidline_rules_title,
.landing_page_container
  .guideline_days_container
  .guidline_rules_bottom
  .guidline_rules_title,
.landing_page_container
  .guideline_days_container
  .guidline_rules_right
  .guidline_rules_title {
  font-size: 22px;
}
.landing_page_container
  .guideline_days_container
  .guidline_rules_left
  .guidline_rules_contant,
.landing_page_container
  .guideline_days_container
  .guidline_rules_bottom
  .guidline_rules_contant,
.landing_page_container
  .guideline_days_container
  .guidline_rules_right
  .guidline_rules_contant {
  width: 80%;
  margin: 30px 0 60px 0;
}
.landing_page_container
  .guideline_days_container
  .guidline_rules_left
  .guidline_question,
.landing_page_container
  .guideline_days_container
  .guidline_rules_bottom
  .guidline_question,
.landing_page_container
  .guideline_days_container
  .guidline_rules_right
  .guidline_question {
  font-size: 18px;
  margin-bottom: 10px;
  text-align: start;
}
.landing_page_container
  .guideline_days_container
  .guidline_rules_left
  .guidline_underline,
.landing_page_container
  .guideline_days_container
  .guidline_rules_bottom
  .guidline_underline,
.landing_page_container
  .guideline_days_container
  .guidline_rules_right
  .guidline_underline {
  width: 100%;
  height: 10px;
  background: #d9d9d9;
  margin-bottom: 10px;
}
.landing_page_container
  .guideline_days_container
  .guidline_rules_left
  .guidline_answer_para,
.landing_page_container
  .guideline_days_container
  .guidline_rules_bottom
  .guidline_answer_para,
.landing_page_container
  .guideline_days_container
  .guidline_rules_right
  .guidline_answer_para {
  font-size: 15px;
  line-height: 18px;
  text-align: start;
}

@media only screen and (max-width: 1171px) {
  .landing_page_container
    .guideline_details_container
    .guideline_details_contant
    .guideline_details_contant_left
    .guideline_details_contant_heading,
  .landing_page_container
    .guideline_details_container
    .guideline_details_contant_reverse
    .guideline_details_contant_left
    .guideline_details_contant_heading {
    font-size: 30px;
  }
}
@media only screen and (max-width: 1025px) {
  .landing_page_container
    .guideline_details_container
    .guideline_details_contant
    .guideline_details_contant_left
    .guideline_details_contant_heading,
  .landing_page_container
    .guideline_details_container
    .guideline_details_contant_reverse
    .guideline_details_contant_left
    .guideline_details_contant_heading {
    font-size: 25px;
  }
}
@media only screen and (max-width: 977px) {
  .landing_page_container
    .guideline_days_container
    .guidline_rules_left
    .guidline_question,
  .landing_page_container
    .guideline_days_container
    .guidline_rules_bottom
    .guidline_question,
  .landing_page_container
    .guideline_days_container
    .guidline_rules_right
    .guidline_question {
    font-size: 15px;
  }
}
@media only screen and (max-width: 888px) {
  .landing_page_container
    .guideline_details_container
    .guideline_details_contant
    .guideline_details_contant_left
    .guideline_details_contant_heading,
  .landing_page_container
    .guideline_details_container
    .guideline_details_contant_reverse
    .guideline_details_contant_left
    .guideline_details_contant_heading {
    font-size: 20px;
  }
  .landing_page_container .guideline_days_container {
    width: 100%;
    margin-top: 50px;
    padding: 0 100px 0 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: 'aeonik-pro-trial', sans-serif;
  }
  .landing_page_container .guideline_days_container .guidline_rules_left,
  .landing_page_container .guideline_days_container .guidline_rules_bottom,
  .landing_page_container .guideline_days_container .guidline_rules_right {
    width: 49%;
    text-align: start;
    padding: 15px 15px;
  }
}
@media only screen and (max-width: 768px) {
  .landing_page_container .guideline_container .guideline_contant {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .landing_page_container
    .guideline_container
    .guideline_contant
    .guideline_contant_left
    .guideline_contant_heading {
    font-size: 60px;
  }
  .landing_page_container
    .faqs_container
    .faqs_contant
    .faqs_contant_left
    .faqs_contant_heading {
    font-size: 65px;
  }
  .landing_page_container
    .guideline_container
    .guideline_contant
    .guideline_contant_left,
  .landing_page_container
    .guideline_container
    .guideline_contant
    .guideline_contant_right {
    width: 100%;
    text-align: center;
  }
  .landing_page_container
    .guideline_container
    .guideline_contant
    .guideline_contant_left {
    margin-bottom: 20px;
  }
  .landing_page_container
    .guideline_details_container
    .guideline_details_contant {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .landing_page_container
    .guideline_details_container
    .guideline_details_contant_reverse {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 80px 0;
  }
  .landing_page_container
    .guideline_details_container
    .guideline_details_contant
    .guideline_details_contant_left,
  .landing_page_container
    .guideline_details_container
    .guideline_details_contant_reverse
    .guideline_details_contant_left {
    width: 100%;
  }
  .landing_page_container
    .guideline_details_container
    .guideline_details_contant
    .guideline_details_contant_right,
  .landing_page_container
    .guideline_details_container
    .guideline_details_contant_reverse
    .guideline_details_contant_right {
    width: 100%;
  }

  .landing_page_container .faqs_container .faqs_contant {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .landing_page_container .faqs_container .faqs_contant .faqs_contant_left,
  .landing_page_container .faqs_container .faqs_contant .faqs_contant_right {
    width: 100%;
    text-align: center;
  }
  .landing_page_container .faqs_container .faqs_contant .faqs_contant_left {
    margin-bottom: 30px;
  }
  .landing_page_container .guideline_days_container {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 656px) {
  .landing_page_container
    .guideline_days_container
    .guidline_rules_left
    .guidline_rules_title,
  .landing_page_container
    .guideline_days_container
    .guidline_rules_bottom
    .guidline_rules_title,
  .landing_page_container
    .guideline_days_container
    .guidline_rules_right
    .guidline_rules_title {
    font-size: 20px;
  }
  .landing_page_container
    .guideline_days_container
    .guidline_rules_left
    .guidline_question,
  .landing_page_container
    .guideline_days_container
    .guidline_rules_bottom
    .guidline_question,
  .landing_page_container
    .guideline_days_container
    .guidline_rules_right
    .guidline_question {
    font-size: 15px;
    margin-bottom: 10px;
    text-align: start;
  }
  .landing_page_container
    .guideline_days_container
    .guidline_rules_left
    .guidline_answer_para,
  .landing_page_container
    .guideline_days_container
    .guidline_rules_bottom
    .guidline_answer_para,
  .landing_page_container
    .guideline_days_container
    .guidline_rules_right
    .guidline_answer_para {
    font-size: 12px;
    line-height: 15px;
    text-align: start;
  }
}

@media only screen and (max-width: 602px) {
  .landing_page_container .guideline_container {
    padding: 80px 40px 20px 40px;
  }
  .landing_page_container .guideline_details_container {
    padding: 60px 40px;
  }
  .landing_page_container .faqs_container {
    padding: 25px 40px 20px 40px;
  }
}
@media only screen and (max-width: 520px) {
  .landing_page_container .guideline_days_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .landing_page_container .guideline_days_container .guidline_rules_left,
  .landing_page_container .guideline_days_container .guidline_rules_bottom,
  .landing_page_container .guideline_days_container .guidline_rules_right {
    width: 100%;
  }
}
@media only screen and (max-width: 425px) {
  .landing_page_container
    .guideline_container
    .guideline_contant
    .guideline_contant_left
    .guideline_contant_heading {
    font-size: 45px;
  }
  .landing_page_container
    .faqs_container
    .faqs_contant
    .faqs_contant_left
    .faqs_contant_heading {
    font-size: 50px;
  }
  .landing_page_container .guideline_container {
    padding: 50px 20px 15px 20px;
  }
  .landing_page_container .guideline_details_container {
    padding: 60px 40px;
  }
  .landing_page_container .faqs_container {
    padding: 15px 20px 15px 20px;
  }
}
@media only screen and (max-width: 375px) {
  .landing_page_container .guideline_container {
    padding: 40px 10px 5px 10px;
  }
  .landing_page_container .guideline_details_container {
    padding: 60px 40px;
  }
  .landing_page_container .faqs_container {
    padding: 5px 10px 5px 10px;
  }
  .landing_page_container .register_event_container .contact_details {
    font-size: 12px;
  }
}
