.input_dynamic_box {
  width: 100%;
  border: 2px solid #fffafd;
  border-left: 7px solid hsl(324, 100%, 99%);
  padding: 7px 10px 7px 30px;
  line-height: 30px;
  font-size: 16px;
  font-family: 'aeonik-pro-trial', sans-serif;
  font-weight: 500;
  background: linear-gradient(
    to right,
    rgba(225, 225, 225, 0.5),
    rgba(225, 225, 225, 0) 5%
  );
  color: #fffafd;
}
.input_alert-msg {
  margin: 10px 0 20px 0;
  font-size: 13px;
  color: red;
}
.input_dynamic_box .inputfield {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 17px;
  color: #000000;
  background: #ffffff;
  padding: 5px 15px;
}
.inputfield::placeholder {
  font-size: 16px;
}
