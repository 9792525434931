.landing_page_container {
  width: 100%;
}

.landing_page_container .header_box {
  width: 100%;
  padding: 5px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: #f5f5f7;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 2px,
    rgba(90, 125, 188, 0.05) 0px 2px 4px;
}

.landing_page_container .header_box .header_logo {
  width: 30%;
  padding-left: 65px;
}
.landing_page_container .header_box .header_logo img {
  width: 70%;
}

.landing_page_container .header_box .navbar_box {
  width: 70%;
}
.landing_page_container .header_box .navbar_box ul {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
}
.landing_page_container .header_box .navbar_box ul li {
  font-size: 18px;
  font-weight: 600;
  padding: 0 10px;
  border-right: 1px solid #000000;
}
.landing_page_container .header_box .navbar_box ul .register_list_style {
  border-right: none;
}
.landing_page_container .header_box .navbar_box ul li a {
  text-decoration: none;
  color: #1f1d1d;
}
.landing_page_container .header_box .navbar_box ul li .register_navlink_style {
  font-weight: 700;
  color: #1f1d1d;
}

.landing_page_container .banner_container {
  width: 100%;
  background: #2e2f33;
  padding: 100px 100px 20px 100px;
  /* margin-top: 70px; */
}
.landing_page_container .banner_container .banner_contant {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.landing_page_container .banner_container .banner_contant .banner_contant_left,
.landing_page_container
  .banner_container
  .banner_contant
  .banner_contant_right {
  width: 50%;
}
.landing_page_container
  .banner_container
  .banner_contant
  .banner_contant_left
  .banner_contant_para {
  font-size: 55px;
  margin-bottom: 10px;
  font-family: 'aeonik-pro-trial', sans-serif;
  margin-bottom: 8px;
  color: #ffffff;
  font-weight: 300;
}
.landing_page_container
  .banner_container
  .banner_contant
  .banner_contant_left
  .banner_contant_header {
  font-size: 45px;
  font-family: 'aeonik-pro-trial', sans-serif;
  color: #ffffff;
  font-weight: 600;
  background: #7700ea;
}
.landing_page_container
  .banner_container
  .banner_contant
  .banner_contant_left
  .banner_contant_place_date {
  font-size: 15px;
  color: #ffffff;
  margin: 40px 0;
  font-family: 'aeonik-pro-trial', sans-serif;
  letter-spacing: 2px;
}
.landing_page_container
  .banner_container
  .banner_contant
  .banner_contant_left
  .banner_contant_place_date
  span {
  margin-right: 30px;
}
.landing_page_container
  .banner_container
  .banner_contant
  .banner_contant_left
  .banner_contant_place_date
  span
  .landing_Icon {
  width: 18px;
}
.home_reg_btn_slide {
  color: #fff;
  background: #000136;
  border-radius: 0px;
  padding: 10px 30px;
  margin: 20px 0 10px 0;
  display: inline-block;
  font-family: 'aeonik-pro-trial', sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
  border-right: 15px solid #7700ec;
  box-shadow: inset 0 0 0 0 #7700ec;
  cursor: pointer;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.home_reg__slide_left:hover {
  padding-right: 20px;
  box-shadow: inset -125px 0 0 0 #7700ec;
}
.landing_page_container
  .banner_container
  .banner_contant
  .banner_contant_right
  .image_container_style {
  width: 100%;
  text-align: center;
}

.landing_page_container
  .banner_container
  .banner_contant
  .banner_contant_right
  .image_container_style
  .slideImage {
  width: 70%;
}

.landing_page_container .deadline_timer_box {
  width: 100%;
  padding: 15px 0;
}
.landing_page_container .deadline_timer_box .timer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.landing_page_container .deadline_timer_box .timer .timer_contant {
  /* align-items: center; */
  text-align: center;
  margin: 0 20px;
}
.landing_page_container
  .deadline_timer_box
  .timer
  .timer_contant
  .timer_contant_days {
  font-size: 43px;
  font-weight: 600;
  padding: 0 5px;
  background-color: #7700ec;
  color: #ffffff;
}
.landing_page_container
  .deadline_timer_box
  .timer
  .timer_contant
  .timer_contant_hours {
  font-size: 43px;
  font-weight: 600;
  padding: 0 5px;
  background-color: #00d87d;
  color: #ffffff;
}
.landing_page_container
  .deadline_timer_box
  .timer
  .timer_contant
  .timer_contant_minutes {
  font-size: 43px;
  font-weight: 600;
  padding: 0 5px;
  background-color: #fccf00;
  color: #ffffff;
}
.landing_page_container
  .deadline_timer_box
  .timer
  .timer_contant
  .timer_contant_seconds {
  font-size: 43px;
  font-weight: 600;
  padding: 0 5px;
  background-color: #8aaeff;
  color: #ffffff;
}
.landing_page_container .deadline_timer_box .timer .timer_dots img {
  width: 20px;
}
.landing_page_container
  .deadline_timer_box
  .timer
  .timer_contant
  .timer_contant_column_two {
  font-size: 12px;
  font-weight: 600;
  font-family: 'aeonik-pro-trial', sans-serif;
  margin-top: 1px;
}

.landing_page_container .DataCenter_container {
  width: 100%;
  padding: 40px 40px;
  background: #f5f5f7;
  text-align: center;
}
.landing_page_container .DataCenter_container .DataCenter_healding {
  font-size: 45px;
  font-family: 'aeonik-pro-trial', sans-serif;
  font-weight: 900;
  line-height: 70px;
  margin-bottom: 35px;
}
.landing_page_container
  .DataCenter_container
  .DataCenter_healding
  .DataCenter_text {
  padding: 4px;
  color: #000000;
  background: #7700ea;
}
.landing_page_container .DataCenter_container .datacenter_para_box {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.landing_page_container
  .DataCenter_container
  .datacenter_para_box
  .DataCenter_para {
  font-size: 22px;
  margin-bottom: 20px;
  font-family: 'aeonik-pro-trial', sans-serif;
  line-height: 28px;
  text-align: start;
}
.landing_page_container .DataCenter_container .home_video {
  width: 100%;
  height: 70%;
  margin-top: 60px;
}

.landing_page_container .schedule_event_container {
  width: 100%;
  padding: 40px 60px;
  text-align: center;
}
.landing_page_container .schedule_event_container .schedule_healding {
  font-size: 65px;
  font-family: 'aeonik-pro-trial', sans-serif;
  font-weight: 700;
  margin-bottom: 30px;
  color: #7700ea;
}
.landing_page_container .schedule_event_container .schedule_days_container {
  width: 80%;
  margin: 100px auto 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-family: 'aeonik-pro-trial', sans-serif;
}
.landing_page_container
  .schedule_event_container
  .schedule_days_container
  .schedule_days_left,
.landing_page_container
  .schedule_event_container
  .schedule_days_container
  .schedule_days_right {
  width: 48%;
  text-align: start;
  margin-left: 60px;
}
.landing_page_container
  .schedule_event_container
  .schedule_days_container
  .schedule_days_left
  .schedule_days_title,
.landing_page_container
  .schedule_event_container
  .schedule_days_container
  .schedule_days_right
  .schedule_days_title {
  font-size: 22px;
}
.landing_page_container
  .schedule_event_container
  .schedule_days_container
  .schedule_days_left
  .schedule_days_title
  span,
.landing_page_container
  .schedule_event_container
  .schedule_days_container
  .schedule_days_right
  .schedule_days_title
  span {
  font-size: 15px;
  color: #7700ea;
  margin:"5px 0 0 0";
}
/* .landing_page_container
  .schedule_event_container
  .schedule_days_container
  .schedule_days_left
  .schedule_days_title
  .schedule_days_title_subpart,
.landing_page_container
  .schedule_event_container
  .schedule_days_container
  .schedule_days_right
  .schedule_days_title
  .schedule_days_title_subpart {
  font-size: 15px;
  color: #7700ea;
  margin:"0";
} */
.landing_page_container
  .schedule_event_container
  .schedule_days_container
  .schedule_days_left
  .schedule_days_contant,
.landing_page_container
  .schedule_event_container
  .schedule_days_container
  .schedule_days_right
  .schedule_days_contant {
  width: 60%;
  margin: 50px 0;
}
.landing_page_container
  .schedule_event_container
  .schedule_days_container
  .schedule_days_left
  .schedule_days_contant
  .schedule_time,
.landing_page_container
  .schedule_event_container
  .schedule_days_container
  .schedule_days_right
  .schedule_days_contant
  .schedule_time {
  font-size: 18px;
  margin-bottom: 10px;
}
.landing_page_container
  .schedule_event_container
  .schedule_days_container
  .schedule_days_left
  .schedule_days_contant
  .schedule_underline,
.landing_page_container
  .schedule_event_container
  .schedule_days_container
  .schedule_days_right
  .schedule_days_contant
  .schedule_underline {
  width: 100%;
  height: 10px;
  background: #d9d9d9;
  margin-bottom: 10px;
}
.landing_page_container .register_event_container {
  width: 100%;
  padding: 40px 60px;
  text-align: center;
  background: #f5f5f7;
}
.landing_page_container .register_event_container .register_healding {
  font-size: 45px;
  font-family: 'aeonik-pro-trial', sans-serif;
  font-weight: 700;
  margin-bottom: 35px;
}
.landing_page_container .register_event_container .button_slide {
  color: #fff;
  background: #000136;
  border-radius: 0px;
  padding: 18px 36px;
  margin-bottom: 20px;
  display: inline-block;
  font-family: 'aeonik-pro-trial', sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
  border-right: 15px solid #7700ec;
  box-shadow: inset 0 0 0 0 #7700ec;
  cursor: pointer;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}
.landing_page_container .register_event_container .slide_down:hover {
  padding-right: 10px;
  box-shadow: inset -140px 0 0 0 #7700ec;
}
.landing_page_container .register_event_container .contact_details {
  font-size: 14px;
  margin: 6px 0;
  font-family: 'aeonik-pro-trial', sans-serif;
}

@media only screen and (max-width: 1198px) {
  .landing_page_container .DataCenter_container .datacenter_para_box {
    width: 70%;
  }
}
@media only screen and (max-width: 1070px) {
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .banner_contant_para {
    font-size: 45px;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .banner_contant_header {
    font-size: 35px;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .banner_contant_place_date {
    font-size: 13px;
    margin: 30px 0;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .Primary {
    font-size: 13px;
  }
}
@media only screen and (max-width: 1024px) {
  .landing_page_container .schedule_event_container .schedule_days_container {
    width: 90%;
    margin: 100px auto 0 auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-family: 'aeonik-pro-trial', sans-serif;
  }
  .landing_page_container .DataCenter_container .DataCenter_healding {
    font-size: 35px;
    font-family: 'aeonik-pro-trial', sans-serif;
    font-weight: 900;
    line-height: 70px;
    margin-bottom: 35px;
  }
}
@media only screen and (max-width: 977px) {
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .banner_contant_para {
    font-size: 40px;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .banner_contant_header {
    font-size: 30px;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .banner_contant_place_date {
    font-size: 11px;
    margin: 30px 0;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .Primary {
    font-size: 11px;
  }
}
@media only screen and (max-width: 917px) {
  .landing_page_container .DataCenter_container .datacenter_para_box {
    width: 80%;
  }
}
@media only screen and (max-width: 896px) {
  .landing_page_container .schedule_event_container .schedule_days_container {
    width: 100%;
    margin: 100px auto 0 auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-family: 'aeonik-pro-trial', sans-serif;
  }
}

@media only screen and (max-width: 880px) {
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .banner_contant_para {
    font-size: 37px;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .banner_contant_header {
    font-size: 27px;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .banner_contant_place_date {
    font-size: 11px;
    margin: 30px 0;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .Primary {
    font-size: 11px;
  }
}
@media only screen and (max-width: 835px) {
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .banner_contant_para {
    font-size: 32px;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .banner_contant_header {
    font-size: 25px;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .banner_contant_place_date {
    font-size: 8px;
    margin: 25px 0;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .Primary {
    font-size: 8px;
  }
}

@media only screen and (max-width: 830px) {
  .landing_page_container .header_box .header_logo {
    font-size: 10px;
  }
  .landing_page_container .header_box .navbar_box ul li {
    font-size: 16px;
  }
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_left,
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_right {
    width: 48%;
    text-align: start;
    margin-left: 30px;
  }
}
@media only screen and (max-width: 768px) {
  .landing_page_container .header_box {
    padding: 8px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .landing_page_container .header_box .header_logo {
    width: 100%;
    margin-bottom: 4px;
    padding-left: 0px;
    text-align: center;
  }
  .landing_page_container .header_box .header_logo img {
    width: 30%;
  }
  .landing_page_container .header_box .navbar_box {
    width: 100%;
  }
  .landing_page_container .header_box .navbar_box ul {
    justify-content: center;
  }
  .landing_page_container .header_box .navbar_box ul li a {
    font-size: 17px;
  }
  .landing_page_container .banner_container {
    padding-top: 130px;
  }
  .landing_page_container .banner_container .banner_contant {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left,
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_right {
    width: 100%;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left {
    text-align: center;
    margin-bottom: 35px;
    margin-top: 15px;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .banner_contant_para {
    font-size: 40px;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .banner_contant_header {
    font-size: 35px;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .banner_contant_place_date {
    font-size: 14px;
    margin: 20px 0;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .banner_contant_place_date
    span {
    margin-right: 15px;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .Primary {
    font-size: 14px;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .banner_contant_place_date
    span
    .landing_Icon {
    width: 10px;
  }
  .landing_page_container .DataCenter_container .home_video {
    width: 90%;
  }
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_left,
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_right {
    width: 50%;
  }
  .landing_page_container .schedule_event_container {
    width: 100%;
    padding: 10px 60px;
  }
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_left
    .schedule_days_title,
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_right
    .schedule_days_title {
    font-size: 19px;
  }
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_left
    .schedule_days_title
    span,
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_right
    .schedule_days_title
    span {
    font-size: 17px;
  }
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_left
    .schedule_days_contant
    .schedule_time,
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_right
    .schedule_days_contant
    .schedule_time {
    font-size: 15px;
  }
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_left
    .schedule_days_contant
    .schedule_time_para,
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_right
    .schedule_days_contant
    .schedule_time_para {
    font-size: 11px;
  }
  .landing_page_container .DataCenter_container .datacenter_para_box {
    width: 90%;
  }
}
@media only screen and (max-width: 700px) {
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .banner_contant_para {
    font-size: 35px;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .banner_contant_header {
    font-size: 30px;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .banner_contant_place_date {
    font-size: 12px;
    margin: 20px 0;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .Primary {
    font-size: 12px;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_right
    .image_container_style
    .slideImage {
    width: 90%;
  }
}
@media only screen and (max-width: 696px) {
  .landing_page_container .DataCenter_container .DataCenter_healding {
    font-size: 30px;
    font-family: 'aeonik-pro-trial', sans-serif;
    font-weight: 900;
    line-height: 70px;
    margin-bottom: 35px;
  }
}
@media only screen and (max-width: 660px) {
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_left
    .schedule_days_title,
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_right
    .schedule_days_title {
    font-size: 17px;
  }
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_left
    .schedule_days_title
    span,
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_right
    .schedule_days_title
    span {
    font-size: 15px;
  }
}
@media only screen and (max-width: 642px) {
  .landing_page_container .header_box .header_logo img {
    width: 35%;
  }
  .landing_page_container .header_box .navbar_box ul li a {
    font-size: 17px;
  }
}
@media only screen and (max-width: 611px) {
  .landing_page_container .DataCenter_container .DataCenter_healding {
    font-size: 25px;
    font-family: 'aeonik-pro-trial', sans-serif;
    font-weight: 900;
    line-height: 70px;
    margin-bottom: 35px;
  }
}
@media only screen and (max-width: 604px) {
  .landing_page_container .schedule_event_container .schedule_healding {
    font-size: 30px;
  }
  .landing_page_container .register_event_container .register_healding {
    font-size: 30px;
  }
}
@media only screen and (max-width: 600px) {
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_left
    .schedule_days_contant
    .schedule_time,
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_right
    .schedule_days_contant
    .schedule_time {
    font-size: 12px;
  }
}
@media only screen and (max-width: 584px) {
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_left
    .schedule_days_title,
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_right
    .schedule_days_title {
    font-size: 15px;
  }
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_left
    .schedule_days_title
    span,
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_right
    .schedule_days_title
    span {
    font-size: 13px;
  }
}
@media only screen and (max-width: 556px) {
  .landing_page_container .header_box .navbar_box ul li a {
    font-size: 15px;
  }
}

@media only screen and (max-width: 550px) {
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .banner_contant_para {
    font-size: 30px;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .banner_contant_header {
    font-size: 25px;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .banner_contant_place_date {
    font-size: 10px;
    margin: 20px 0;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .Primary {
    font-size: 10px;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_right
    .image_container_style
    .slideImage {
    width: 90%;
  }
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_left
    .schedule_days_title,
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_right
    .schedule_days_title {
    font-size: 14px;
  }
}

@media only screen and (max-width: 528px) {
  .landing_page_container .DataCenter_container .DataCenter_healding {
    font-size: 20px;
    font-family: 'aeonik-pro-trial', sans-serif;
    font-weight: 900;
    line-height: 40px;
    margin-bottom: 35px;
  }
}
@media only screen and (max-width: 520px) {
  .landing_page_container .header_box .header_logo {
    font-size: 8px;
  }
  .landing_page_container .header_box .navbar_box ul li {
    font-size: 13px;
  }
}

@media only screen and (max-width: 504px) {
  .landing_page_container .schedule_event_container {
    padding: 40px 20px;
  }
}
@media only screen and (max-width: 500px) {
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .banner_contant_para {
    font-size: 26px;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .banner_contant_header {
    font-size: 22px;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .banner_contant_place_date {
    font-size: 8px;
    margin: 20px 0;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .Primary {
    font-size: 8px;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_right
    .image_container_style
    .slideImage {
    width: 80%;
  }
}
@media only screen and (max-width: 493px) {
  .landing_page_container .banner_container {
    margin-top: 115px;
  }
}
@media only screen and (max-width: 471px) {
  .landing_page_container
    .deadline_timer_box
    .timer
    .timer_contant
    .timer_contant_days {
    font-size: 30px;
  }
  .landing_page_container
    .deadline_timer_box
    .timer
    .timer_contant
    .timer_contant_hours {
    font-size: 30px;
  }
  .landing_page_container
    .deadline_timer_box
    .timer
    .timer_contant
    .timer_contant_minutes {
    font-size: 30px;
  }
  .landing_page_container
    .deadline_timer_box
    .timer
    .timer_contant
    .timer_contant_seconds {
    font-size: 30px;
  }
}

@media only screen and (max-width: 460px) {
  .landing_page_container .banner_container {
    margin-top: 100px;
  }
  .landing_page_container .header_box .header_logo {
    font-size: 8px;
  }
  .landing_page_container .header_box .navbar_box ul li {
    font-size: 10px;
  }
}
@media only screen and (max-width: 457px) {
  .landing_page_container .DataCenter_container .DataCenter_healding {
    font-size: 20px;
  }
}
@media only screen and (max-width: 450px) {
  .landing_page_container .banner_container {
    padding: 15px 10px 15px 5px;
  }
}

@media only screen and (max-width: 444px) {
  .landing_page_container .header_box .header_logo img {
    width: 35%;
  }
  .landing_page_container .header_box .navbar_box ul li a {
    font-size: 14px;
  }
}
@media only screen and (max-width: 430px) {
  .landing_page_container .banner_container {
    padding-top: 100px;
    margin-top: 0px;
  }
}
@media only screen and (max-width: 429px) {
  .landing_page_container .banner_container {
    padding-top: 80px;
    margin-top: 20px;
  }
  .landing_page_container .schedule_event_container .schedule_days_container {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px auto 0 auto;
    font-family: 'aeonik-pro-trial', sans-serif;
  }
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_left,
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_right {
    width: 100%;
    text-align: start;
    margin-left: 10px;
  }
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_left
    .schedule_days_title,
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_right
    .schedule_days_title {
    font-size: 22px;
  }
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_left
    .schedule_days_title
    span,
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_right
    .schedule_days_title
    span {
    font-size: 20px;
    color: #7700ea;
  }
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_left
    .schedule_days_contant,
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_right
    .schedule_days_contant {
    width: 60%;
    margin: 50px 0;
  }
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_left
    .schedule_days_contant
    .schedule_time,
  .landing_page_container
    .schedule_event_container
    .schedule_days_container
    .schedule_days_right
    .schedule_days_contant
    .schedule_time {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .landing_page_container
    .DataCenter_container
    .datacenter_para_box
    .DataCenter_para {
    font-size: 16px;
    margin-bottom: 20px;
    font-family: 'aeonik-pro-trial', sans-serif;
    line-height: 28px;
    text-align: start;
  }
  .landing_page_container .DataCenter_container {
    width: 100%;
    padding: 40px 10px;
    background: #f5f5f7;
    text-align: center;
  }
}
@media only screen and (max-width: 418px) {
  .landing_page_container .header_box .header_logo {
    font-size: 8px;
  }
  .landing_page_container .header_box .navbar_box ul li {
    font-size: 10px;
  }
  .landing_page_container
    .deadline_timer_box
    .timer
    .timer_contant
    .timer_contant_days {
    font-size: 20px;
  }
  .landing_page_container
    .deadline_timer_box
    .timer
    .timer_contant
    .timer_contant_hours {
    font-size: 20px;
  }
  .landing_page_container
    .deadline_timer_box
    .timer
    .timer_contant
    .timer_contant_minutes {
    font-size: 20px;
  }
  .landing_page_container
    .deadline_timer_box
    .timer
    .timer_contant
    .timer_contant_seconds {
    font-size: 20px;
  }
  .landing_page_container .deadline_timer_box .timer .timer_dots img {
    width: 15px;
  }
  .landing_page_container
    .deadline_timer_box
    .timer
    .timer_contant
    .timer_contant_column_two {
    font-size: 10px;
  }
}

@media only screen and (max-width: 396px) {
  .landing_page_container .header_box .header_logo {
    font-size: 6px;
  }
  .landing_page_container .header_box .navbar_box ul li {
    font-size: 8px;
  }
}
@media only screen and (max-width: 390px) {
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left {
    margin-bottom: 25px;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_right
    .image_container_style
    .slideImage {
    width: 90%;
  }
}
/* @media only screen and (max-width: 383px) {
  .landing_page_container .DataCenter_container .DataCenter_healding {
    font-size: 30px;
  }
} */
@media only screen and (max-width: 375px) {
  .landing_page_container .banner_container {
    padding-top: 50px;
    margin-top: 40px;
  }
  .landing_page_container
    .banner_container
    .banner_contant
    .banner_contant_left
    .banner_contant_place_date
    span
    .landing_Icon {
    width: 9px;
  }
  .landing_page_container .register_event_container .button_slide {
    padding: 18px 17px;
  }
}
@media only screen and (max-width: 369px) {
  .landing_page_container .DataCenter_container .DataCenter_healding {
    font-size: 16px;
    font-family: 'aeonik-pro-trial', sans-serif;
    font-weight: 900;
    line-height: 35px;
    margin-bottom: 35px;
  }
}
@media only screen and (max-width: 350px) {
  .landing_page_container .DataCenter_container .DataCenter_para {
    font-size: 12px;
  }
  .landing_page_container .schedule_event_container .DataCenter_para {
    font-size: 12px;
  }
  .landing_page_container .register_event_container .DataCenter_para {
    font-size: 12px;
  }
}
@media only screen and (max-width: 344px) {
  .landing_page_container .banner_container {
    padding-top: 30px;
    margin-top: 60px;
  }
  .landing_page_container .header_box .header_logo {
    font-size: 5px;
  }
  .landing_page_container .header_box .navbar_box ul li {
    font-size: 5px;
  }
  .landing_page_container
    .deadline_timer_box
    .timer
    .timer_contant
    .timer_contant_days {
    font-size: 12px;
  }
  .landing_page_container
    .deadline_timer_box
    .timer
    .timer_contant
    .timer_contant_hours {
    font-size: 12px;
  }
  .landing_page_container
    .deadline_timer_box
    .timer
    .timer_contant
    .timer_contant_minutes {
    font-size: 12px;
  }
  .landing_page_container
    .deadline_timer_box
    .timer
    .timer_contant
    .timer_contant_seconds {
    font-size: 12px;
  }
  .landing_page_container .deadline_timer_box .timer .timer_dots img {
    width: 7px;
  }
  .landing_page_container
    .deadline_timer_box
    .timer
    .timer_contant
    .timer_contant_column_two {
    font-size: 8px;
  }
}
/* @media only screen and (max-width: 327px) {
  .landing_page_container .DataCenter_container .DataCenter_healding {
    font-size: 25px;
  }
} */
@media only screen and (max-width: 317px) {
  .landing_page_container .register_event_container .register_healding {
    font-size: 22px;
  }
}
@media only screen and (max-width: 293px) {
  .landing_page_container .register_event_container .button_slide {
    padding: 12px 15px;
  }
}
@media only screen and (max-width: 284px) {
  .landing_page_container .DataCenter_container .DataCenter_healding {
    font-size: 20px;
  }
}
